(function() {
  $(function() {
    var $block, $body, $document, $items, $subItems, handleHoverMenu, handleSubMenu, handleTouchMenu, handleUserMenu;
    $block = $(".header");
    if (!$block.length) {
      return;
    }
    $body = $("body");
    $document = $(document);
    $items = $block.find(".main-add-menu__list-item").filter(".has-sub");
    $subItems = $block.find(".main-add-menu__sub-item, .main-add-menu__list-item.has-sub").filter(".has-sub");
    handleHoverMenu = function(event) {
      var $this;
      $this = $(event.currentTarget);
      switch (event.type) {
        case "mouseenter":
          $body.addClass("menu-active");
          return $this.addClass("menu-drop-active menu-drop-show");
        case "mouseleave":
          $body.removeClass("menu-active");
          return $this.removeClass("menu-drop-active menu-drop-show");
      }
    };
    handleTouchMenu = function(event) {
      var $this;
      $this = $(event.target);
      return $items.each(function() {
        var $currentItem;
        $currentItem = $(this);
        if ($currentItem.is($this)) {
          if (!$this.hasClass("menu-drop-active") && !$this.hasClass("menu-drop-show")) {
            $body.addClass("menu-active");
            return $items.removeClass("menu-drop-active menu-drop-show").filter($this).addClass("menu-drop-active menu-drop-show");
          } else {
            $body.removeClass("menu-active");
            return $this.removeClass("menu-drop-active menu-drop-show");
          }
        } else if (!$currentItem.is($this) && $currentItem.has($this).length === 0 && $currentItem.hasClass("menu-drop-active menu-drop-show")) {
          $body.removeClass("menu-active");
          return $items.removeClass("menu-drop-active menu-drop-show");
        }
      });
    };
    handleSubMenu = function(event) {
      var $this;
      $this = $(event.currentTarget);
      if ($block.hasClass("open")) {
        if (!$this.hasClass("open")) {
          return $(".main-add-menu__sub-item.has-sub, .main-add-menu__list-item.has-sub").removeClass("open").filter($this).addClass("open");
        } else {
          return $this.removeClass("open");
        }
      }
    };
    handleUserMenu = function(event) {
      var $this;
      $this = $(event.currentTarget);
      return $this.toggleClass("open");
    };
    $items.on("mouseleave mouseenter", handleHoverMenu);
    return $document.on("click", ".main-add-menu__list-item.has-sub", function(event) {
      handleUserMenu(event);
      return event.stopPropagation();
    });
  });

}).call(this);
