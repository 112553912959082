(function() {
  $(function() {
    $('.video-modal').fancybox({
      openEffect: 'none',
      closeEffect: 'none',
      width: 960,
      height: 540,
      aspectRatio: true,
      padding: 0,
      helpers: {
        media: {}
      }
    });
    $('.image-modal').fancybox();
    $('.select7').select7();
    return $('[data-toggle="tooltip"]').tooltip();
  });

}).call(this);
