(function() {
  $(".upload-file").each(function() {
    var $block, label;
    $block = $(this);
    label = $block.find("input").data('label');
    return $block.find("input").on("change", function() {
      var $name, f;
      $name = $block.find(".upload-file__name");
      f = this.files;
      if (f.length) {
        $block.addClass("active");
        if (f.length > 1) {
          return $name.text(f.length + " " + label);
        } else {
          return $name.text(f[0].name);
        }
      }
    });
  });

}).call(this);
