(function() {
  $(function() {
    var $searchEngine;
    $searchEngine = $('.search-engine');
    if (!$searchEngine.length) {

    }
  });

}).call(this);
