(function() {
  $(function() {
    var $block, $body, $document, $toggle, handleUserPanelDropdown;
    $block = $('.user-panel');
    if (!$block.length) {
      return;
    }
    $document = $(document);
    $body = $('body');
    $toggle = $block.find('.user-panel__label');
    handleUserPanelDropdown = function(event) {
      var $this;
      $this = $(event.target);
      if ($toggle.is($this)) {
        $block.toggleClass('active');
        return $body.removeClass('notification-box-open setting-panel-open').toggleClass('user-panel-open');
      } else if (!$block.is($this) && $block.has($this).length === 0 && $block.hasClass('active')) {
        $block.removeClass('active');
        return $body.removeClass('user-panel-open');
      }
    };
    return $document.on('click', handleUserPanelDropdown);
  });

}).call(this);
