(function() {
  $(function() {
    var delay;
    delay = function(ms, func) {
      return setTimeout(func, ms);
    };
    $.fn.extend({
      validateBATForm: function() {
        return $(this).each(function(idx, el) {
          var $block, $check, $tabItem;
          $block = $(el);
          $check = $block.find(".product-valider__check input");
          $tabItem = $block.find(".product-valider__tab");
          $check.change(function(e) {
            var $this, target;
            $this = $(e.target);
            target = $this.closest(".product-valider__check").data('target');
            $tabItem.slideUp(200);
            return $(target).slideDown(200);
          });
          return delay(100, function() {
            return $block.find(".product-valider__check input:checked").trigger('change');
          });
        });
      }
    });
    return $('.product-valider').validateBATForm();
  });

}).call(this);
