(function() {
  $(function() {
    var $block, $document, $headerPhoneMenu, $headerPhoneMenuItems, $headerPhoneSubItems, $headerPhoneToggle, $headerTabletBlock, $headerTabletMenu, $headerTabletToggle, $searchBlock, $searchEngine, $searchField, $toggleSearch, handleHeaderDropdown;
    $block = $('.header');
    if (!$block.length) {
      return;
    }
    $document = $(document);
    $searchBlock = $block.find('.header__search');
    $searchField = $block.find('.search-field');
    $searchEngine = $block.find('.search-engine');
    $toggleSearch = $block.find('.header__search-icon');
    $headerPhoneToggle = $block.find('.header__toggle_phone');
    $headerPhoneSubItems = $block.find('.main-menu__sub-item').filter('.has-sub');
    $headerPhoneMenuItems = $block.find('.main-menu__list-item').filter('.has-sub');
    $headerPhoneMenu = $block.find('.header__menu');
    $headerTabletBlock = $block.find('.header__menu-inner');
    $headerTabletToggle = $block.find('.header__toggle_tablet');
    $headerTabletMenu = $block.find('.header__menu-add');
    handleHeaderDropdown = function(event) {
      var $this;
      $this = $(event.target);
      if ($toggleSearch.is($this)) {
        $block.removeClass('open');
        $headerTabletBlock.removeClass('open');
        $searchBlock.toggleClass('open');
        if ($searchBlock.hasClass('open')) {
          $searchEngine.typeahead('val', null);
          $searchField.addClass('active');
          return $searchEngine.focus();
        }
      } else if ($headerPhoneToggle.is($this)) {
        $searchBlock.removeClass('open');
        $headerTabletBlock.removeClass('open');
        $headerPhoneMenuItems.removeClass('open');
        return $block.toggleClass('open');
      } else if ($headerTabletToggle.is($this)) {
        $searchBlock.removeClass('open');
        $block.removeClass('open');
        return $headerTabletBlock.toggleClass('open');
      } else if (!$searchBlock.is($this) && $searchBlock.has($this).length === 0 && $searchBlock.hasClass('open')) {
        $searchBlock.removeClass('open');
        $searchField.removeClass('active');
        return $searchEngine.typeahead('val', null);
      } else if (!$headerPhoneMenu.is($this) && $headerPhoneMenu.has($this).length === 0 && $block.hasClass('open')) {
        return $block.removeClass('open');
      } else if (!$headerTabletMenu.is($this) && $headerTabletMenu.has($this).length === 0 && $headerTabletBlock.hasClass('open')) {
        return $headerTabletBlock.removeClass('open');
      }
    };
    return $document.on('click', handleHeaderDropdown);
  });

}).call(this);
