(function() {
  $(function() {
    var $block, $more;
    $block = $(".content-box");
    if (!$block.length) {
      return;
    }
    $more = $block.find(".content-box__more");
    return $more.on("click", function(e) {
      return $(this).closest(".content-box__list").toggleClass("active");
    });
  });

}).call(this);
