(function() {
  var indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

  $.fn.mod = function(name, value) {
    if (value == null) {
      value = true;
    }
    return this.each(function() {
      var $el, blockName, classAttr, toAdd, toRemove;
      $el = $(this);
      classAttr = $el.attr("class");
      blockName = classAttr.split(" ")[0];
      toRemove = classAttr.match(new RegExp(blockName + "_" + name + "\\S*", "g"));
      toAdd = null;
      if (value) {
        toAdd = blockName + "_" + name;
        if (value !== true) {
          toAdd += "_" + value;
        }
        if (toRemove && indexOf.call(toRemove, toAdd) >= 0) {
          toRemove.splice(toRemove.indexOf(toAdd), 1);
        }
      }
      if (toRemove && toRemove.length > 0) {
        $el.removeClass(toRemove.join(" "));
      }
      if (toAdd) {
        $el.addClass(toAdd);
      }
      return $el.trigger("mod", [name, value]);
    });
  };

  $.block = function(name) {
    return $("." + name);
  };

  $.fn.block = function(name) {
    return this.find("." + name);
  };

  $.fn.element = function(name) {
    var blockName, classAttr;
    classAttr = this.attr("class");
    blockName = classAttr.split(" ")[0];
    return this.find("." + blockName + "__" + name);
  };

}).call(this);
