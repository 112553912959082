(function($)
{
    $(document).ready(function()
    {

        $('.templateFilterForm').find('.content-nav__menu-item').on('click', function(e)
        {
            e.preventDefault();

            var filterId = $(this).data("filterId");
            var filterValue = $(this).data("filterValue");
            var $filterField = $('#'+filterId);
            var $filterForm = $filterField.parents('.templateFilterForm');

            if(filterValue === 'all') {
                $filterField.find('option:selected').removeAttr('selected');
            } else {
                $filterField.val(filterValue);
            }

            $filterForm.submit();
        });
    });
})(jQuery);