(function() {
  $(function() {
    var $block, $toggle;
    $block = $(".messages");
    if (!$block.length) {
      return;
    }
    $toggle = $block.find(".messages__check");
    return $toggle.on("click", function(e) {
      return $(this).closest(".messages__item").toggleClass("selected");
    });
  });

}).call(this);
